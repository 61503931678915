import { css } from 'styled-components';
import { Interpolation } from 'styled-components';
import { DeviceWidths } from 'statics/lib/types/_measures';

/**
 * Solution based on:
 * @link https://www.codevertiser.com/styled-components-media-queries/
 *
 * @todo Fix media css(...args as cssArgs) typing as I can't solve it with my current
 * knowledge.
 */

export const breakpoints: DeviceWidths = {
	PHONE_PORTRAIT_MIN_WIDTH: '360px',
	PHONE_PORTRAIT_MAX_WIDTH: '575px',
	PHONE_LANDSCAPE_MIN_WIDTH: '576px',
	PHONE_LANDSCAPE_MAX_WIDTH: '767px',
	TABLET_PORTRAIT_MIN_WIDTH: '768px',
	TABLET_PORTRAIT_MAX_WIDTH: '959px',
	TABLET_LANDSCAPE_MIN_WIDTH: '960px',
	TABLET_LANDSCAPE_MAX_WIDTH: '1023px',
	LAPTOP_MIN_WIDTH: '1024px',
	LAPTOP_MAX_WIDTH: '1440px',
	DESKTOP_MIN_WIDTH: '1441px',
	DESKTOP_MAX_WIDTH: '1800px',
	BIG_DESKTOP_MIN_WIDTH: '1801px',
	BIG_DESKTOP_MAX_WIDTH: '2560px',
};

/**
 * FIXME: Correct 'mediaArgs' type probably must be something like:
 * 		TemplateStringsArray | TemplateStringsArray[] | CSSObject[];
 * but can't make it work with my current knowledge.
 */
type mediaArgs = any;
type cssArgs = [TemplateStringsArray, ...Interpolation<any>[]];

export const media = {
	// XS | SM - MOBILE PORTRAIT
	xsMin: (...args: mediaArgs) => css`
		@media ((min-width: ${breakpoints.PHONE_PORTRAIT_MIN_WIDTH})) {
			${css(...(args as cssArgs))};
		}
	`,
	xs: (...args: mediaArgs) => css`
		@media ((min-width: ${breakpoints.PHONE_PORTRAIT_MIN_WIDTH}) and (max-width: ${breakpoints.PHONE_PORTRAIT_MAX_WIDTH})) {
			${css(...(args as cssArgs))};
		}
	`,
	xsMax: (...args: mediaArgs) => css`
		@media ((max-width: ${breakpoints.PHONE_PORTRAIT_MAX_WIDTH})) {
			${css(...(args as cssArgs))};
		}
	`,

	// SM - MOBILE LANDSCAPE
	smMin: (...args: mediaArgs) => css`
		@media (min-width: ${breakpoints.PHONE_LANDSCAPE_MIN_WIDTH}) {
			${css(...(args as cssArgs))};
		}
	`,
	sm: (...args: mediaArgs) => css`
		@media (min-width: ${breakpoints.PHONE_LANDSCAPE_MIN_WIDTH}) and (max-width: ${breakpoints.PHONE_LANDSCAPE_MAX_WIDTH}) {
			${css(...(args as cssArgs))};
		}
	`,
	smMax: (...args: mediaArgs) => css`
		@media (max-width: ${breakpoints.PHONE_LANDSCAPE_MAX_WIDTH}) {
			${css(...(args as cssArgs))};
		}
	`,

	// MD - TABLET
	mdMin: (...args: mediaArgs) => css`
		@media (min-width: ${breakpoints.TABLET_PORTRAIT_MIN_WIDTH}) {
			${css(...(args as cssArgs))};
		}
	`,
	md: (...args: mediaArgs) => css`
		@media (min-width: ${breakpoints.TABLET_PORTRAIT_MIN_WIDTH}) and (max-width: ${breakpoints.TABLET_LANDSCAPE_MAX_WIDTH}) {
			${css(...(args as cssArgs))};
		}
	`,
	mdMax: (...args: mediaArgs) => css`
		@media (max-width: ${breakpoints.TABLET_LANDSCAPE_MAX_WIDTH}) {
			${css(...(args as cssArgs))};
		}
	`,

	// LG - LAPTOP
	lgMin: (...args: mediaArgs) => css`
		@media (min-width: ${breakpoints.LAPTOP_MIN_WIDTH}) {
			${css(...(args as cssArgs))};
		}
	`,
	lg: (...args: mediaArgs) => css`
		@media (min-width: ${breakpoints.LAPTOP_MIN_WIDTH}) and (max-width: ${breakpoints.LAPTOP_MAX_WIDTH}) {
			${css(...(args as cssArgs))};
		}
	`,
	lgMax: (...args: mediaArgs) => css`
		@media (max-width: ${breakpoints.LAPTOP_MAX_WIDTH}) {
			${css(...(args as cssArgs))};
		}
	`,

	// XL - DESKTOP
	xlMin: (...args: mediaArgs) => css`
		@media (min-width: ${breakpoints.DESKTOP_MIN_WIDTH}) {
			${css(...(args as cssArgs))};
		}
	`,
	xl: (...args: mediaArgs) => css`
		@media (min-width: ${breakpoints.DESKTOP_MIN_WIDTH}) and (max-width: ${breakpoints.DESKTOP_MAX_WIDTH}) {
			${css(...(args as cssArgs))};
		}
	`,
	xlMax: (...args: mediaArgs) => css`
		@media (max-width: ${breakpoints.DESKTOP_MAX_WIDTH}) {
			${css(...(args as cssArgs))};
		}
	`,

	// XXL - BIG DESKTOP
	xxlMin: (...args: mediaArgs) => css`
		@media (min-width: ${breakpoints.BIG_DESKTOP_MIN_WIDTH}) {
			${css(...(args as cssArgs))};
		}
	`,
	xxl: (...args: mediaArgs) => css`
		@media (min-width: ${breakpoints.BIG_DESKTOP_MIN_WIDTH}) and (max-width: ${breakpoints.BIG_DESKTOP_MAX_WIDTH}) {
			${css(...(args as cssArgs))};
		}
	`,
	xxlMax: (...args: mediaArgs) => css`
		@media (max-width: ${breakpoints.BIG_DESKTOP_MAX_WIDTH}) {
			${css(...(args as cssArgs))};
		}
	`,
};
