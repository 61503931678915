import { IContentLoaderProps } from 'react-content-loader';
import { TitleSkeletonSC } from './style.skeleton';

export const TitleSkeleton = (props: IContentLoaderProps) => (
	<TitleSkeletonSC
		speed={2}
		width="100%"
		height={32}
		backgroundColor="#f3f3f3"
		foregroundColor="#ece0e0"
		{...props}>
		<rect x="0" y="0" width="100%" height={32} />
	</TitleSkeletonSC>
);
