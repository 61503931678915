import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { LinkContent } from 'ui-library/components/atoms/Link/style';
import { media } from 'ui-library/lib/breakpoints';

const bodySizes = theme('size', {
	XL: css`
		font-size: var(--font-body-size-XL-mobile);
		${media.md`
			font-size: var(--font-body-size-XL-tablet);
		`}
		${media.lgMin`
			font-size: var(--font-body-size-XL-laptop);
		`}
	`,
	L: css`
		font-size: var(--font-body-size-L-mobile);
		${media.md`
			font-size: var(--font-body-size-L-tablet);
		`}
		${media.lgMin`
			font-size: var(--font-body-size-L-laptop);
		`}
	`,
	M: css`
		font-size: var(--font-body-size-M-mobile);
		${media.md`
			font-size: var(--font-body-size-M-tablet);
		`}
		${media.lgMin`
			font-size: var(--font-body-size-M-laptop);
		`}
	`,
	S: css`
		font-size: var(--font-body-size-S-mobile);
		${media.md`
			font-size: var(--font-body-size-S-tablet);
		`}
		${media.lgMin`
			font-size: var(--font-body-size-S-laptop);
		`}
	`,
	XS: css`
		font-size: var(--font-body-size-XS-mobile);
		${media.md`
			font-size: var(--font-body-size-XS-tablet);
		`}
		${media.lgMin`
			font-size: var(--font-body-size-XS-laptop);
		`}
	`,
});

const bodyStyle = theme('variant', {
	light: css`
		color: color-mix(
			in srgb,
			var(--color-text-onSurface)
				var(--opacity-emphasis-surface-high),
			transparent
		);
		.glyph {
			fill: color-mix(
				in srgb,
				var(--color-text-onSurface)
					var(--opacity-emphasis-surface-high),
				transparent
			);
		}
	`,
	dark: css`
		color: var(--color-basic-white);
		.glyph {
			fill: var(--color-basic-white);
		}
	`,
});

const paragraphMargin = theme('margin', {
	default: css`
		p {
			margin-bottom: var(--spacing-_16);
		}
	`,
	32: css`
		p {
			margin: 0 var(--spacing-_32) var(--spacing-_32);
		}
	`,
});

const BodyContent = styled.div`
	font-family: var(--font-body-family-regular);
	line-height: 1.3;
	letter-spacing: 0.5px;
	width: -webkit-fill-available;
	${bodySizes}
	${bodyStyle}
	${paragraphMargin}
	.svgText {
		position: relative;
		top: 3px;
	}

	${LinkContent} + & {
		margin-top: var(--spacing-_40);
	}

	// NOTE: This padding is used as new line separation.
	p + p,
	ul {
		padding-top: var(--spacing-_16);
	}

	ul {
		text-align: start;
	}
`;

export { BodyContent };
