import { keyframes, styled } from 'styled-components';

/** HACK: FIXME: react-content-loader has a bug with loading animation.
 *@see https://github.com/danilowoz/react-content-loader/issues/257
 */
const skeletonLoading = keyframes`
 0% {
   fill: hsl(0, 0%, 87%);
 }
 100% {
   fill: hsl(0, 0%, 92%);
 }
`;

const SkeletonContent = styled.section<{ $justifyContent: string }>`
	box-sizing: border-box;
	overflow: hidden;
	display: flex;
	justify-content: ${({ $justifyContent }) => $justifyContent};
	width: 100%;
	rect,
	circle,
	ellipse,
	line,
	path,
	polygon,
	polyline {
		animation: ${skeletonLoading} 1s linear infinite alternate;
	}
`;

export { SkeletonContent };
