import { css } from 'styled-components';

export const outlined = css`
	border: 1px solid var(--color-neutral-_500);
	color: var(--color-primary-_500);
	background-color: transparent;
	&:hover:not([disabled]) {
		background-color: color-mix(
			in srgb,
			var(--color-primary-_500) var(--opacity-overlay-hover),
			transparent
		);
	}
	&:focus {
		border-color: var(--color-primary-_500);
		background-color: color-mix(
			in srgb,
			var(--color-primary-_500) var(--opacity-overlay-focused),
			transparent
		);
	}
	&:active:not([disabled]) {
		border-color: var(--color-neutral-_500);
		background-color: color-mix(
			in srgb,
			var(--color-primary-_500) var(--opacity-overlay-pressed),
			transparent
		);
	}
	&:disabled {
		border-color: var(--color-neutral-_300);
		color: rgba(0, 0, 0, 0.38);
	}
`;
