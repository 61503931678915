import { css } from 'styled-components';

export const unstyled = css`
	background: unset;
	box-shadow: unset;
	padding: unset;
	&:hover {
		background: unset;
		box-shadow: unset;
		cursor: pointer;
	}
	&:disabled {
		background: unset;
		box-shadow: unset;
	}
`;
