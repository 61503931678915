import { css } from 'styled-components';
import { outlined as buttonOutlined } from 'ui-library/components/atoms/Button/style.outlined';

export const outlined = css`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	${buttonOutlined}
`;
