import React from 'react';
import ContentLoader from 'react-content-loader';
import { SkeletonContent } from './style';

export const Skeleton = ({
	renderSkeleton = () => <DefaultSkeleton />,
	justifyContent = 'center',
}) => {
	return (
		<SkeletonContent $justifyContent={justifyContent}>
			{renderSkeleton()}
		</SkeletonContent>
	);
};

const DefaultSkeleton = (props: React.PropsWithChildren) => (
	<ContentLoader
		speed={2}
		width={400}
		height={160}
		viewBox="0 0 400 160"
		backgroundColor="#f3f3f3"
		foregroundColor="#ece0e0"
		{...props}>
		<rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
		<rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
		<rect x="0" y="56" rx="3" ry="3" width="410" height="12" />
		<rect x="0" y="78" rx="3" ry="3" width="380" height="12" />
		<rect x="0" y="100" rx="3" ry="3" width="178" height="12" />
		<circle cx="20" cy="20" r="20" />
	</ContentLoader>
);
