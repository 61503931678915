import { IContentLoaderProps } from 'react-content-loader';
import { BodySkeletonSC } from './style.skeleton';

export const BodySkeleton = (props: IContentLoaderProps) => (
	<BodySkeletonSC
		speed={2}
		width="100%"
		height={72}
		backgroundColor="#f3f3f3"
		foregroundColor="#ece0e0"
		{...props}>
		<rect x="0" y="0" width="100%" height={20} />
		<rect x="0" y="26" width="100%" height={20} />
		<rect x="0" y="52" width="100%" height={20} />
	</BodySkeletonSC>
);
